<div class="chart">
  <div
    *ngFor="let bar of data$ | async; trackBy: trackBar"
    class="chart__bar"
    [class.chart__bar--loading]="bar.loading"
    [style.height.%]="bar.value"
    [matTooltip]="bar.tooltip"
    matTooltipTouchGestures="off"
    [@.disabled]="bar.loading"
    @inOutBar>
  </div>
</div>
