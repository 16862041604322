<div class="wrapper">
  <div
    class="block"
    *ngFor="let card of blocks$ | async; trackBy: trackIndex">

    <component-info
      *ngFor="let block of card; trackBy: trackIndex"
      [type]="block.type"
      [icon]="block.icon"
      [title]="block.title"
      [value]="block.$value"
      [sizeType]="block.sizeType">
    </component-info>
  </div>
</div>
