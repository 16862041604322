<div
  [class]="
    ' info info--' + type +
    (!value ? ' info--loading' : '')
  ">

  <component-info-icon *ngIf="icon" class="info__icon" [icon]="icon"></component-info-icon>
  <div class="info__content">
    <div class="info__text">
      <div class="info__title">{{title}}</div>
      <div
        *ngIf="type !== 'chart' && type !== 'block-proposers'"
        [@loadingAnimation]="!value"
        class="info__value">

        <span loadingAnimationText>{{value}}&nbsp;</span>
      </div>
    </div>
  </div>
  <div *ngIf="type === 'chart'" class="info__chart">
    <component-chart [data]="value" [type]="sizeType"></component-chart>
  </div>
  <div *ngIf="type === 'block-proposers'" class="info__data">
    <component-block-proposers [data]="value"></component-block-proposers>
  </div>
</div>
