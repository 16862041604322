<table
  *ngIf="nodesList$ | async as nodes"
  class="table">

  <thead>
    <tr class="table__head" *ngIf="sorting$ | async as sorting">
      <td
        class="table__cell table__cell--xsmall table__cell--pinned"
        matTooltip="Pin node">

        <mat-icon fontSet="material-icons-outlined" class="table__head-icon">
          favorite_border
        </mat-icon>
      </td>
      <td
        *ngFor="let column of columns$ | async; trackBy: trackColumn"
        [class]="'table__cell ' + variants(column, sorting)"
        (click)="changeOrderBy(column)"
        [matTooltip]="column.name">

        <mat-icon fontSet="material-icons-outlined" class="table__head-icon">
          {{column.icon}}

          <mat-icon
            fontSet="material-icons-outlined"
            *ngIf="sorting.column.name === column.name"
            class="table__head-icon table__head-icon--order">

            {{sorting.direction == 1 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
          </mat-icon>
        </mat-icon>
      </td>
    </tr>
  </thead>
  <tbody>
    <app-dashboard-nodes-row
      class="table__row"
      *ngFor="let id of nodes"
      [rowId]="id">
    </app-dashboard-nodes-row>
    <app-dashboard-nodes-row-loading
      class="table__row"
      *ngFor="let i of loadingRows$ | async">
    </app-dashboard-nodes-row-loading>
  </tbody>
</table>
