// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // ethstatsService: 'wss://baklava-ethstats.celo-testnet.org',
  ethstatsService: 'wss://baklava-ethstats.celo-testnet.org',
  blockscoutUrl: 'http://localhost:3000',
  graphqlBlockscoutUrl: 'http://localhost:3000',
  submenu: {
    blockscout: 'http://localhost:3000',
    explorer: 'https://validators.poa.network/poa-dapps-validators',
  },
  menu: {
    github: 'https://github.com/LastHyphen',
    blog: 'https://facebook.com/DijetalRealm',
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'  // Included with Angular CLI.
